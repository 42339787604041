/** @jsx jsx */
import { Grid, Heading, Image, jsx, Text } from "theme-ui"
import { Box } from "theme-ui"
import Reveal from "react-reveal/Fade"
import Zoom from "react-reveal/Zoom"
import Img from "gatsby-image"
import Wrapper from "./wrapper"
import useLanguage from "../hooks/useLanguage"

const AboutSection = ({ data }) => {
  const isEn = useLanguage() === "en"
  return (
    <Box
      sx={{
        minHeight: ["90vh", null, null, "75vh", "50vh", "100vh"],
        mb: 50,
        position: "relative",
      }}
      id="about"
    >
      <Box
        sx={{
          width: "100%",
          height: 70,
        }}
      />
      <Wrapper>
        <Box
          sx={{
            width: ["100%", "100%", "100%", 370],
            height: [490, 490, 490, 490, 500, 500],
            backgroundColor: "secondary",
            marginInlineStart: [0, 0, 0, 0, 100, 170],
            display: ["none", null, null, null, null, "block"],
            position: "absolute",
            zIndex: -1,
            top: 15,
          }}
        />
        <Box>
          <Grid columns={[1, 1, 1, 1, 2, 2]} gap={3}>
            <Zoom>
              <Box
                sx={{
                  height: 350,
                  width: ["auto", "auto", "auto", "100%", "100%", 600],
                }}
              >
                <Img fluid={data.upImg.childImageSharp.fluid} />
              </Box>
            </Zoom>
            <Box m={2}>
              <Reveal top>
                <Heading as="h1" mb={2}>
                  {data.title}
                </Heading>
              </Reveal>
              <Reveal left>
                <Text sx={{ textAlign: "justify", fontSize: 24 }}>
                  {data.description}
                </Text>
              </Reveal>
            </Box>
          </Grid>

          <Grid columns={[1, 1, 1, 1, 2, 2]} gap={3} mt={[0, 0, 0, 0, 0, 4]}>
            <Reveal right>
              <Text
                sx={{ textAlign: "justify", fontSize: 24 }}
                m={2}
                mt={[0, 0, 0, 0, "2rem", "5rem"]}
              >
                {data.addtionalDescription}
              </Text>
            </Reveal>
            <Zoom>
              <Box
                sx={{
                  height: 350,
                  width: ["auto", "auto", "auto", "100%", "100%", 600],
                }}
              >
                <Img fluid={data.downImg.childImageSharp.fluid} />
              </Box>
            </Zoom>
          </Grid>
        </Box>
        <Box
          sx={{
            width: ["100%", "100%", "100%", 370],
            height: [490, 490, 490, 490, 500, 500],
            backgroundColor: "primary",
            marginInlineEnd: [0, 0, 0, 0, 100, 170],
            display: ["none", null, null, null, null, "block"],
            position: "absolute",
            zIndex: -1,
            bottom: -40,
            right: 0,
            ...(!isEn && { left: 0, right: "unset" }),
          }}
        />
      </Wrapper>
      <Box
        sx={{
          width: "100%",
          height: 30,
        }}
      />
    </Box>
  )
}

AboutSection.propTypes = {}

export default AboutSection
