import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Home from "../components/HomeSection"
import About from "../components/AboutSection"
import Contact from "../components/ContactSection"

export default function HomeLayout({ data }) {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <SEO title={frontmatter?.homeTitle} />
      <Home
        data={{
          title: frontmatter.homeTitle,
          description: frontmatter.homeDescription,
        }}
      />
      <About
        data={{
          title: frontmatter.aboutTitle,
          description: frontmatter.aboutDescription,
          addtionalDescription: frontmatter.aboutAdditionalDescription,
          upImg: frontmatter.aboutImgOne,
          downImg: frontmatter.aboutImgTwo
        }}
      />
      <Contact
        title={frontmatter.contactTitle}
        description={frontmatter.contactDescription}
        branches={frontmatter.branches}
      />
    </Layout>
  )
}

export const homeQuery = graphql`
  query($lang: String!) {
    markdownRemark(
      frontmatter: { lang: { eq: $lang } }
      fileAbsolutePath: { regex: "/pages/index/" }
    ) {
      frontmatter {
        lang
        homeTitle
        homeDescription
        aboutTitle
        aboutDescription
        aboutAdditionalDescription
        contactTitle
        contactDescription
        aboutImgOne {
          childImageSharp {
            fluid(maxWidth: 1600) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        aboutImgTwo {
          childImageSharp {
            fluid(maxWidth: 1600) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        branches {
          id
          title
          img {
            childImageSharp {
              fluid(maxWidth: 1600) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          imgUrl
          address
          phone
          mobile
          email
        }
      }
      html
    }
  }
`
