import React from "react"
import { Box, Button, Card, Flex, Heading, Text, Image } from "theme-ui"
import Icon from "react-icons-kit"
import { instagram } from "react-icons-kit/feather/instagram"
import { facebook } from "react-icons-kit/feather/facebook"
import { socialWhatsappOutline } from "react-icons-kit/ionicons/socialWhatsappOutline"
import { phone } from "react-icons-kit/feather/phone"
import { smartphone } from "react-icons-kit/feather/smartphone"
import { mapPin } from "react-icons-kit/feather/mapPin"
import { mail } from "react-icons-kit/feather/mail"
import Img from "gatsby-image"
import Reveal from "react-reveal/Fade"
import Zoom from "react-reveal/Zoom"
import Wrapper from "./wrapper"
import useLanguage from "../hooks/useLanguage"
import weChatQR from "../images/WeChat.png"
import contactUsBG from "../images/contact-us.jpg"

const tabStyle = {
  border: 0,
  fontSize: "14px",
  color: "text",
  transition: "0.4s",
  padding: "10px 5px",
  background: "transparent",
  flex: 1,
  fontWeight: 600,
  minWidth: 150,
  borderRadius: 0,
  borderBottom: "2px solid transparent",
}

export default function ContactSection({ title, description, branches }) {
  const [selected, setSelected] = React.useState(1)
  const isEn = useLanguage() === "en"

  return (
    <Box
      id="contact"
      sx={{
        minHeight: ["90vh", null, null, "75vh", "60vh", "100vh"],
        position: "relative",
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: 70,
        }}
      />
      <Box
        sx={{
          width: "calc(((100% - 1130px) / 2) + 640px)",
          height: [200, null, null, 300, 500],
          backgroundColor: "secondary",
          position: "absolute",
          zIndex: -1,
          left: isEn ? "unset" : 0,
          right: !isEn ? "unset" : 0,
          top: 0,
        }}
      />
      <Box
        sx={{
          width: "calc(((100% - 1130px) / 2) + 640px)",
          height: [200, null, null, 300, 500],
          backgroundColor: "primary",
          position: "absolute",
          zIndex: -1,
          right: isEn ? "unset" : 0,
          left: !isEn ? "unset" : 0,
          bottom: 0,
        }}
      />
      <Box
        sx={{
          width: ["95%", null, null, null, null, "85%"],
          margin: "0 auto",
          backgroundImage: `url(${contactUsBG})`,
          backgroundSize: "contain",
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            backgroundColor: "#30383cb8",
            color: "white",
            textAlign: "center",
            padding: 30,
          }}
        >
          <Wrapper>
            <Reveal top>
              <Heading>{title}</Heading>
            </Reveal>
            <Text>{description}</Text>
            <Flex my={3} sx={{ justifyContent: "center" }}>
              {[
                {
                  icon: facebook,
                  link: "https://www.facebook.com/daranybawazeer",
                },
                {
                  icon: instagram,
                  link: "https://www.instagram.com/daranybawazeer/",
                },
                {
                  icon: socialWhatsappOutline,
                  link: "https://wa.me/967712387070",
                },
              ].map((item, i) => (
                <a
                  key={item.link}
                  href={item.link}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Reveal bottom>
                    <Box
                      sx={{
                        border: "2px solid",
                        borderRadius: "50%",
                        color: "primary",
                        ...(i !== 2 && { marginInlineEnd: "15px" }),
                        p: "6px",
                        transition: "0.4s",
                        ":hover": {
                          opacity: 0.6,
                        },
                      }}
                    >
                      <Icon size={25} icon={item.icon} />
                    </Box>
                  </Reveal>
                </a>
              ))}
            </Flex>
            <Flex mb={3} sx={{ justifyContent: "center" }}>
              <Zoom>
                <Box sx={{ height: 140 }}>
                  <Image
                    src={weChatQR}
                    alt="wechat qr code"
                    sx={{ height: "100%" }}
                  />
                </Box>
              </Zoom>
            </Flex>
            <Card
              sx={{
                backgroundColor: "white",
                borderRadius: "10px",
                color: "text",
              }}
            >
              <Box sx={{ overflowX: "auto" }}>
                <Flex sx={{ justifyContent: "spaceBetween" }}>
                  {branches.map(branch => (
                    <Button
                      key={branch.id}
                      sx={{
                        ...tabStyle,
                        ...(selected === branch.id && {
                          color: "primary",
                          borderBottom: "2px solid;",
                        }),
                      }}
                      onClick={() => setSelected(branch.id)}
                    >
                      {branch.title}
                    </Button>
                  ))}
                </Flex>
              </Box>
              <Box
                p={3}
                sx={{ minHeight: ["auto", null, null, null, null, 400] }}
              >
                {branches.map(branch => {
                  if (branch.id === selected)
                    return (
                      <Box
                        sx={{
                          position: "relative",
                          borderRadius: 10,
                          overflow: "hidden",
                        }}
                      >
                        <Reveal top cascade>
                          <Box
                            sx={{
                              position: "absolute",
                              backgroundColor: "#30383cb5",
                              padding: 2,
                              color: "white",
                              zIndex: 10,
                            }}
                          >
                            <Box mb={1}>
                              <Flex sx={{ alignItems: "center" }}>
                                <Icon size={20} icon={mapPin} />
                                <Text
                                  sx={{
                                    marginInlineStart: 10,
                                    fontSize: [
                                      null,
                                      "12px",
                                      null,
                                      null,
                                      "18px",
                                    ],
                                    fontWeight: 600,
                                  }}
                                >
                                  {branch.address}
                                </Text>
                              </Flex>
                            </Box>
                            <Box mb={1}>
                              <Flex
                                sx={{
                                  alignItems: "center",
                                  textDecoration: "none",
                                  color: "white",
                                }}
                                as="a"
                                href={`tel:${branch.mobile}`}
                              >
                                <Icon size={20} icon={smartphone} />
                                <Text
                                  sx={{
                                    marginInlineStart: 10,
                                    fontSize: [
                                      null,
                                      "12px",
                                      null,
                                      null,
                                      "18px",
                                    ],
                                    fontWeight: 600,
                                  }}
                                >
                                  {branch.mobile}
                                </Text>
                              </Flex>
                            </Box>
                            <Box mb={1}>
                              <Flex
                                sx={{
                                  alignItems: "center",
                                  textDecoration: "none",
                                  color: "white",
                                }}
                              >
                                <Icon size={20} icon={phone} />
                                <Text
                                  sx={{
                                    marginInlineStart: 10,
                                    fontSize: [
                                      null,
                                      "12px",
                                      null,
                                      null,
                                      "18px",
                                    ],
                                    fontWeight: 600,
                                  }}
                                >
                                  {branch.phone}
                                </Text>
                              </Flex>
                            </Box>
                            <Box mb={1}>
                              <Flex
                                sx={{
                                  alignItems: "center",
                                  textDecoration: "none",
                                  color: "white",
                                }}
                                as="a"
                                href={`mailto:${branch.email}`}
                              >
                                <Icon size={20} icon={mail} />
                                <Text
                                  sx={{
                                    marginInlineStart: 10,
                                    fontSize: [
                                      null,
                                      "12px",
                                      null,
                                      null,
                                      "18px",
                                    ],
                                    fontWeight: 600,
                                  }}
                                >
                                  {branch.email}
                                </Text>
                              </Flex>
                            </Box>
                          </Box>
                        </Reveal>
                        <a
                          href={branch.imgUrl}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <Zoom>
                            <Img fluid={branch.img.childImageSharp.fluid} />
                          </Zoom>
                        </a>
                      </Box>
                    )
                  return false
                })}
              </Box>
            </Card>
          </Wrapper>
        </Box>
      </Box>
      <Box
        sx={{
          width: "100%",
          height: 70,
        }}
      />
    </Box>
  )
}
