/** @jsx jsx */
import { Flex, Heading, jsx, Text } from "theme-ui"
import { Box } from "theme-ui"
import Icon from "react-icons-kit"
import { instagram } from "react-icons-kit/feather/instagram"
import { facebook } from "react-icons-kit/feather/facebook"
import { socialWhatsappOutline } from "react-icons-kit/ionicons/socialWhatsappOutline"
import Reveal from "react-reveal/Fade"
import Wrapper from "./wrapper"
import icon from "../images/bg.png"
import useLanguage from "../hooks/useLanguage"

const HomeSection = ({ data }) => {
  const isEn = useLanguage() === "en"

  return (
    <Box
      sx={{
        color: "white",
        bg: "secondary",
        height: ["70vh", null, null, null, "100vh", "100vh"],
        pt: 5,
        backgroundImage: `url(${icon})`,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        ...(isEn && { transform: "rotateY(180deg)" }),
      }}
      id="home"
    >
      <Flex
        sx={{
          flexDirection: "column",
          justifyContent: "center",
          position: "absolute",
          right: 20,
          top: 0,
          bottom: 0,
          display: ["none", null, null, null, null, "flex"],
          ...(isEn && {
            transform: "rotateY(180deg)",
          }),
        }}
      >
        {[
          {
            icon: facebook,
            link: "https://www.facebook.com/daranybawazeer",
          },
          {
            icon: instagram,
            link: "https://www.instagram.com/daranybawazeer/",
          },
          {
            icon: socialWhatsappOutline,
            link: "https://wa.me/967712387070",
          },
        ].map(item => (
          <a key={item.link} href={item.link} target="_blank" rel="noreferrer">
            <Reveal right>
              <Box
                sx={{
                  border: "2px solid",
                  borderRadius: "50%",
                  color: "primary",
                  my: "10px",
                  p: "6px",
                  transition: "0.4s",
                  ":hover": {
                    opacity: 0.6,
                  },
                }}
              >
                <Icon size={25} icon={item.icon} />
              </Box>
            </Reveal>
          </a>
        ))}
      </Flex>
      <Wrapper>
        <Flex
          sx={{
            justifyContent: "flex-start",
            alignItems: "center",
            height: ["50vh", null, null, null, "80vh", "80vh"],
            ...(isEn && { transform: "rotateY(180deg)" }),
          }}
        >
          <Box
            sx={{
              backgroundColor: "secondaryTransparent",
              p: 3,
              maxWidth: 780,
            }}
          >
            <Reveal top>
              <Heading
                as="h1"
                mb={3}
                sx={{ fontSize: [20, 20, 30, 40, 50, 65] }}
              >
                {data.title}
              </Heading>
            </Reveal>
            <Reveal bottom>
              <Text
                sx={{
                  textAlign: "justify",
                  fontSize: [null, 20, null, 20, 20, 25],
                }}
              >
                {data.description}
              </Text>
            </Reveal>
          </Box>
        </Flex>
      </Wrapper>
    </Box>
  )
}

HomeSection.propTypes = {}

export default HomeSection
